import { AvailableCurrency, CurrencyListItem } from 'interfaces/currency'

const USDToGBPFixedExchangeRate = 0.8
const USDToAUDFixedExchangeRate = 1.5
const USDToINRFinalExchangeRate = 83

const BASE_CURRENCY_DETAILS: CurrencyListItem = {
  imageUrl: '/App/images/currencies/dollar.png',
  selectedCurrency: 'USD',
  conversionRate: 1,
  symbol: '$',
  locale: 'en-US',
}

const supportedCurrenciesMap = new Map<AvailableCurrency, CurrencyListItem>([
  [
    'INR',
    {
      id: 1,
      imageUrl: '/App/images/currencies/ruppee.png',
      selectedCurrency: 'INR',
      conversionRate: USDToINRFinalExchangeRate,
      symbol: '₹',
      locale: 'en-IN',
    },
  ],
  [
    'USD',
    {
      ...BASE_CURRENCY_DETAILS,
      id: 2,
    },
  ],
  [
    'GBP',
    {
      id: 3,
      // imageUrl: '/App/images/currencies/AuDollar.png',
      imageUrl: '/App/images/currencies/gbp.png',
      selectedCurrency: 'GBP',
      conversionRate: USDToGBPFixedExchangeRate,
      symbol: '£',
      locale: 'en-GB',
    },
  ],
  [
    'AUD',
    {
      id: 4,
      imageUrl: '/App/images/currencies/dollar.png',
      selectedCurrency: 'AUD',
      conversionRate: USDToAUDFixedExchangeRate,
      symbol: '$',
      locale: 'en-AU',
    },
  ],
])

const supportedCurrenciesArray = Array.from(supportedCurrenciesMap, (k) => k[1])

const getCurrencyDetails = (c: AvailableCurrency | undefined) =>
  (c && supportedCurrenciesMap.get(c)) ?? BASE_CURRENCY_DETAILS

export {
  BASE_CURRENCY_DETAILS,
  supportedCurrenciesMap,
  supportedCurrenciesArray,
  getCurrencyDetails,
}
